import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import Carousel, { CarouselImages } from "Components/Carousel"
import SEO from "Components/core/SEO"

interface SafariGameProps {
  data: CarouselImages
}

const SafariGame: React.FC<SafariGameProps & GatsbyProps> = ({
  location,
  data: { smallestImages, smallImages, mediumImages, largeImages },
}) => (
  <StandardLayout location={location}>
    <SEO title="Zoo Game" />
    <h1>Zoo Game</h1>
    <p>
      One of my first projects done in flash was an educational game I did
      during my time in DIFE, it was a simple game that was created as part of a
      3 man team, the basic idea of the game was to have simple games preceding
      random facts about the animal represented in the game.
    </p>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Screenshot of home selection screen",
        "Screenshot of safari zone",
        "Screenshot of selecting lion game",
        "Screenshot of selecting giraffe game",
        "Screenshot of selecting zebra game",
        "Screenshot of mixed up lion puzzle",
        "Screenshot of finished lion puzzle",
        "Screenshot of lion fact",
        "Screenshot of zebra game",
        "Screenshot of zebra game lion running",
        "Screenshot of throwing stone at lion",
        "Screenshot of all lions being scared off",
        "Screenshot of zebra fact",
        "Screenshot of Giraffe game",
        "Screenshot of Giraffe babies found",
        "Screenshot of Giraffe fact",
      ]}
    />
  </StandardLayout>
)
export const query = graphql`
  query {
    smallestImages: allFile(
      filter: { relativePath: { glob: "flash-projects/zoo-safari*" } }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: { relativePath: { glob: "flash-projects/zoo-safari*" } }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: { relativePath: { glob: "flash-projects/zoo-safari*" } }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: { relativePath: { glob: "flash-projects/zoo-safari*" } }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
export default SafariGame
